import React, { FC } from 'react'
import { ColorsTheme } from 'constants/announces/ColorTheme'
import { DateFormatter } from 'components/page/list/DateFormatted'
import { FontSize, Indent, SizeViewport } from 'constants/sizes'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { VerifiedAnnouncement } from 'components/block/verifiedAnnouncement/VerifiedAnnouncement'
import { addCurrentAnnounceAction } from 'actions/announce/announceAction'
import { definitions } from 'api/generated/masked_ads'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { styled } from 'linaria/react'
import { useDispatch } from 'react-redux'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const ListItem: FC<{
  item: definitions['AdResponse']
  notLink?: boolean
}> = ({ item, notLink = false }) => {
  const dispatch = useDispatch()

  const { language } = useShallowEqualSelector(({ system: { language } }) => ({
    language,
  }))

  const handleClick = () => {
    dispatch(addCurrentAnnounceAction(item))
  }

  const {
    id,
    text,
    publicationTime,
    whoIsOwnerTitle,
    ownerAge,
    whoLookingForTitle,
    location,
    isPostedByVerifiedAccount,
    tags,
  } = item

  if (
    id &&
    text &&
    publicationTime &&
    whoIsOwnerTitle &&
    ownerAge &&
    whoLookingForTitle &&
    tags &&
    location &&
    language &&
    language.code
  ) {
    const tagsTitle = tags.map(({ title }) => `#${title}`).join(', ')

    const description = (
      <>
        <Description data-name="announce-description">
          <span data-name="announce-name">{whoIsOwnerTitle}</span>{' '}
          <span data-name="announce-age">{ownerAge}</span>{' '}
          {tagsTitle.length > 0 && (
            <>
              <FormattedMessage
                id="app.search.modification"
                defaultMessage="ищу"
              />{' '}
              <span data-name="announce-location">{tagsTitle}</span>
            </>
          )}
        </Description>
        <DateAnnounce data-name="announce-timestamp">
          <DateFormatter dateTimestamp={Number(publicationTime)} />
        </DateAnnounce>
        {isPostedByVerifiedAccount && <VerifiedAnnouncement />}
      </>
    )

    if (notLink) {
      return (
        <ItemSimple data-name="announce-not-link">
          <AnnounceTitleFull data-name="announce-text">
            {text}
          </AnnounceTitleFull>
          {description}
        </ItemSimple>
      )
    }

    return (
      <ItemLink
        to={mergeAllUrls(language.code, `id${id}`)}
        data-name="announce-link-action"
        onClick={handleClick}
      >
        <AnnounceTitle data-name="announce-text">{text}</AnnounceTitle>
        {description}
      </ItemLink>
    )
  }

  return null
}

export const announceTitleCss = `
  font-size: ${FontSize.base}px;
  line-height: 22px;
  margin: 0 0 ${Indent.indent12}px 0;  
  overflow: hidden; 
  font-weight: normal;
  color: black;    
`

export const itemLinkCss = `
  display: flex;
  flex-direction: column;
  padding: ${Indent.indent32}px;
  text-decoration: none;
  word-break: break-word;
`

export const AnnounceTitle = styled.p`
  ${announceTitleCss};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const AnnounceTitleFull = styled.p`
  ${announceTitleCss};
`

export const Description = styled.p`
  color: ${ColorsTheme.primaryDefaultLight};
  font-size: ${FontSize.smallLabel}px;
  line-height: ${FontSize.noteAutoComplete}px;
  margin: 0 0 ${Indent.indent12}px 0;
`

export const DateAnnounce = styled.div`
  font-size: ${FontSize.smallLabel}px;
  line-height: ${FontSize.smallLabel}px;
  color: black;
`

export const ItemLink = styled(Link)`
  ${itemLinkCss}
`

export const ItemSimple = styled.div`
  ${itemLinkCss};
  padding: 0 0 ${Indent.indent32}px 0;

  @media screen and (max-width: ${SizeViewport.phone}px) {
    padding: 0 0 ${Indent.indent20}px 0;
  }
`
